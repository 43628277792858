define("plutof/translations/est/quick-browse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    button: 'Sirvi kirjeid',
    query: {
      project: 'Projekt',
      collection: 'Kogu',
      taxon: 'Takson',
      text: 'Nimi või ID',
      textPlaceholder: 'nt. TU1234',
      ordering: 'Sorteeri'
    },
    ordering: {
      modified: 'Viimati muudetud',
      taxon: 'Takson'
    },
    popup: {
      taxon: 'Takson',
      taxonRank: 'Taksoni tase',
      taxonVernacularNames: 'Tavanimetused',
      collection: 'Kogu',
      project: 'Projekt',
      area: 'Asukoht',
      collected: 'Kogutud',
      identified: 'Määranud'
    },
    pagination: {
      next: 'Järgmised',
      previous: 'Eelmised'
    }
  };
});