define("plutof/translations/eng/clipboard", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    all: 'all',
    removeMissing: 'Remove missing entries ({{count}})',
    removeInvalid: 'Remove all invalid entries',
    removeMissingTooltip: 'Remove clipboard entries of records that have been deleted.',
    showInvalid: 'Show invalid entries ({{count}})',
    showInvalidTooltip: `Clipboard entries that are not currently usable because they are missing
    searchable information. If the problem persists, please contact the support.`,
    saveToFile: 'Export',
    showOnMap: 'Show on Map',
    loadFullMap: 'Load all',
    unloadFullMap: 'Show only selected',
    analysis: 'Analysis',
    yourAnalysis: 'Your sequence analysis run',
    toAnalysis: 'To analysis',
    sequenceTitle: 'Sequences',
    specimenTitle: 'Specimens',
    observationTitle: 'Observations',
    photobankTitle: 'Photobank Items',
    livingspecimenTitle: 'Living Specimens',
    sequenceNavbarTitle: 'Sequence Clipboard',
    specimenNavbarTitle: 'Specimen Clipboard',
    observationNavbarTitle: 'Observation Clipboard',
    photobankNavbarTitle: 'Photobank Clipboard',
    livingspecimenNavbarTitle: 'Living Specimen Clipboard',
    materialSampleTitle: 'Material Samples',
    refbased: {
      title: 'Reference Based Occurrences',
      fullTitle: 'Reference Based Occurrence Clipboard',
      info: 'View, manage, analyse and export selected reference based occurrences.'
    },
    select: 'Select',
    selected: 'Selected',
    pages: 'Pages',
    noLocationData: 'No location data',
    groupBy: 'Group by',
    massBLASTer: 'massBLASTer',
    megaBlastDataset: 'Blast multiple sequences against UNITE, UNITE Envir. or annotated INSD datasets using Mega BLAST algorithm.',
    ITSx: 'ITSx',
    ExtractITS: 'Extract ITS1 and ITS2 from ribosomal ITS sequences of fungi and other eucaryotes for use in environmental sequencing.',
    assignFungal: 'Assign fungal ITS sequences to UNITE Species Hypotheses.',
    seqAnalysis: 'Sequence analysis',
    yourRuns: 'Your Runs',
    specimenInfo: 'View, manage, analyse and export selected specimens.',
    livingspecimenInfo: 'View, manage, analyse and export selected living specimens.',
    materialsampleInfo: 'View, manage, analyse and export selected material samples.',
    sequenceInfo: 'View, manage, analyse and export selected sequences.',
    observationInfo: 'View, manage, analyse and export selected specimens.',
    photobankInfo: 'View, manage, analyse and export selected collection objects.',
    exportMethod: 'Export method',
    genBankExport: 'GenBank',
    genBankFile: 'Template file (.sbt)',
    exportAllFields: 'All fields',
    exportedToFile: 'File was saved to File Repository as',
    exportFinishesLater: `Exporting this many records will take a while.
    You'll be notified of the process' completion via e-mail.`,
    editFinishesLater: `Updating this many records will take a while.
    You'll be notified of the process' completion via e-mail.`,
    exportFormatCsv: 'CSV',
    exportFormatListOfSpecies: 'List of species',
    exportFormatGenBank: 'GenBank Submission',
    exportFormatFasta: 'FASTA',
    exportFormatDarwinCore: 'Darwin Core Archive',
    exportFormatIndexSeminum: 'Index Seminum',
    export: {
      csv: {
        layers: 'Layers'
      }
    },
    bulkOperations: 'Bulk Operations',
    bulkRemove: 'Remove',
    bulkAdd: 'Add',
    bulkEdit: 'Edit',
    bulkDelete: 'Delete',
    bulkAreaSwitch: 'Switch area',
    bulkAreaEdit: 'Update area',
    bulkActiveInputs: 'Active fields',
    bulkDeleteAll: 'Delete all',
    labelFormat: 'Label format',
    paperSize: 'Paper size',
    labelsIncludeProjectInfo: 'Project info',
    labelsIncludeAreaName: 'Area name',
    labelsUsePredefinedArea: 'Use predefined area name',
    labelsIncludeAllGAs: 'Full list of gathering agents',
    labelsIncludeAllDeterminers: 'Full list of determiners',
    labelsUseAdditionalName: 'Show taxon names present on Taxon name (add.) field',
    labelsUseValidName: 'Replace identified taxon with it\'s valid name if original is synonym',
    labelsUsePhenophase: 'Include phenophase',
    labelsRemarksNull: 'No remarks',
    labelsRemarksGeneral: 'General remarks',
    labelsRemarksIdentification: 'Identification remarks',
    labelsRemarksBoth: 'Both remarks',
    primaryCode: 'Primary code',
    groupCritNone: 'None',
    groupCritIsolationSource: 'By isolation source',
    groupCritHabitatType: 'By habitat type',
    coordinateFormat: 'Coordinate format',
    createdAt: 'Added to clipboard',
    updateSource: (0, _helpers.alias)('Sequences.source'),
    loading: 'Loading...',
    loadingProject: 'Loading project...',
    loadingRecords: 'Loading records...',
    savingArea: 'Saving area and event...',
    savingAccess: 'Saving access rights...',
    savingRecords: 'Saving records...',
    removingEntries: 'Removing clipboard entries...',
    clipboardUsage: 'Clipboard usage',
    clipboardUsageTooltip: 'Number current entries and the limit of this clipboard',
    orderingDisabled: 'Ordering is disabled when clipboard usage exceeds {{limit}} entries.',
    labelStrings: {
      locality: 'Locality',
      substrate: 'Substrate',
      habitat: 'Habitat',
      latitude: 'Lat.',
      longitude: 'Long.',
      altitude: 'Alt.',
      collectors: 'Leg.',
      determiners: 'Det.',
      idprim: 'Specimen id:',
      sequenceTypes: 'Sequenced: ',
      photo: 'Photo in database',
      latlon: 'Lat/Long',
      project: 'Project',
      remarks: 'Remarks',
      attribution: 'Printed in PlutoF',
      andothers: 'et al.',
      additionalids: 'Add. id-s:',
      eventdate: 'Gathered',
      taimeatlasSquare: 'Taimeatlas square',
      ELM: {
        idprim: '',
        collectors: 'leg:',
        determiners: 'det:'
      },
      TA: {
        collectors: 'Leg:',
        determiners: 'Det:'
      }
    },
    labelPrinting: {
      labelPrinting: 'Label Printing',
      willBeAsync: 'Printing of more than 250 labels will take time. You will be notified of completion via email',
      timedOut: 'Printing took too much time and was aborted. Try printing in smaller batches.',
      downloadStarted: 'Downloading...',
      livingSpecimen: {
        tuBotanicalGarden: {
          lifespan: 'Plant lifespan icon'
        },
        tuBotanicalGardenOrchids: {
          logo: 'Orchids logo'
        }
      }
    },
    gbif: {
      title: 'GBIF Publishing',
      dataset: 'Dataset',
      description: 'Add records to, or remove from GBIF publishing datasets.',
      mode: {
        append: {
          label: 'Append',
          tooltip: 'Add to existing records in a GBIF publishing dataset.'
        },
        overwrite: {
          label: 'Overwrite',
          tooltip: 'Remove any existing records from a dataset and add new records.'
        },
        remove: {
          label: 'Remove',
          tooltip: 'Remove records from GBIF publishing datasets.'
        }
      }
    },
    delete: {
      title: 'Delete records',
      result: {
        sync: '{{removed}} records were deleted, {{unremoved}} could not be.',
        async: 'Deleting this many records will take a while. You will be notified of the process\' completion via email.'
      }
    },
    dnaExtraction: {
      addSelected: 'Add selected',
      description: 'Add selected records (maximum 96) to a new, or existing, DNA extraction experiment.',
      title: 'DNA Extraction',
      new: 'New experiment',
      existing: 'Existing experiment',
      laboratory: 'Laboratory',
      experiment: 'Experiment'
    },
    bulkUpdate: {
      description: 'Overwrite or delete record values.',
      addExternalTrait: 'Additional traits',
      fields: {
        sequence: {
          unitestatus: 'Request UNITE code'
        }
      },
      panels: {
        conditionAssessments: 'Condition Assessments'
      },
      info: {
        preparations: 'Add a new Preparation to records.',
        conditionAssessments: 'Add a new Condition Assessment to records.'
      }
    },
    transaction: {
      options: {
        new: 'Create a new transaction',
        existing: 'Use existing transaction'
      },
      add: 'Add selected',
      create: 'Create transaction',
      description: 'Add selected records to a Transaction.',
      isUpdating: `The selected transaction is currently being updated with
            new objects. More objects can be added once the updating process has finished.`,
      title: 'Transaction',
      transaction: 'Transaction'
    },
    actions: {
      addAll: {
        label: 'All',
        tooltip: 'Send all records to Clipboard'
      },
      addPage: {
        label: 'Page',
        tooltip: (0, _helpers.alias)('Search.clipboard.sendPageToClipboard')
      },
      remove: {
        label: 'Remove from Clipboard ({{count}})'
      },
      startCA: {
        label: 'New Condition Assessment ({{count}})'
      },
      startTreatment: {
        label: 'New Treatment ({{count}})'
      },
      startBLASTSHMatching: {
        label: 'New massBLASTer SH matching ({{count}})'
      },
      startSHMatching: {
        label: 'New SH matching ({{count}})'
      },
      printLabels: {
        label: 'Print labels ({{count}})'
      },
      saveAssociatedData: {
        label: 'Save ({{count}})'
      },
      bulkUpdate: {
        label: 'Update'
      },
      deleteRecords: {
        label: 'Delete records'
      },
      export: {
        label: 'Export'
      },
      bulkAnnotate: {
        label: 'Annotate ({{count}})'
      }
    },
    count: {
      some: '({{count}})',
      all: '(all {{count}})'
    },
    citation: {
      dialogTitle: 'When using this dataset cite as',
      citation: 'Citation',
      copy: 'Copy to clipboard',
      copied: 'Copied'
    }
  };
});